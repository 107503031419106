<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="4">
          <label style="font-size: 14px; margin-bottom: 7px" for="code">
            {{ $t("code") }}
          </label>
          <g-field
            :value.sync="selectedItem.code"
            id="code"
            type="number"
            name="code"
          >
          </g-field>
        </b-col>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="date"
            :disabled="true"
          />
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              rules="required"
              label-text="employee"
              field="select"
              name="employee"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.startDate"
            label-text="startVacationForm"
            name="date"
          />
        </b-col>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.endDate"
            label-text="endVacationAt"
            name="date"
          />
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.typeVacationId"
              :dir="isRight ? 'rtl' : 'ltr'"
              rules="required"
              label-text="VacationType"
              field="select"
              name="VacationType"
              :options="VacationTypes"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.adoptedFromId"
              :dir="isRight ? 'rtl' : 'ltr'"
              rules="required"
              label-text="adoptedFrom"
              field="select"
              name="adoptedFrom"
              :options="adoptedFroms"
              :filterFn="(item) => item.isDepartment === true"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- notes -->
      <b-row class="pt-1">
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- accept button -->
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="relief-primary"
            data-action-type="save"
            @click="save('saveOnly')"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ this.$t('save') }}
          </b-button>

          <b-button
            class="ml-1"
            variant="outline-primary"
            data-action-type="save"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ this.$t('saveAndPrint') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import {} from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';

export default {
  mixins: [reportMixin],
  props: ['id'],
  data() {
    return {
      VacationTypes: [],
      adoptedFroms: [],
      filedName: '',
      employees: [],
      selectedItem: {},
    };
  },
  computed: {},
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    // get object in case edit
    if (this.id > 0) {
      this.getSelected();
    }
    this.getEmployee();
    this.getVacationType();
  },

  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    validateDates() {
      this.selectedItem.transactionDate = this.selectedItem.transactionDate ?? this.today;
      this.selectedItem.startDate = this.selectedItem.startDate ?? this.today;
      this.selectedItem.endDate = this.selectedItem.endDate ?? this.today;
    },
    // get vacation type
    getVacationType() {
      this.get({ url: 'TypesVacations' }).then((data) => {
        this.VacationTypes = data;
      });
    },
    // get object from server side
    getSelected() {
      this.get({ url: 'RequestVacation', id: this.id }).then((data) => {
        this.selectedItem = data;
    });
    },

    // get all employees
    getEmployee() {
      this.get({ url: 'employees' }).then((data) => {
        this.employees = data.filter(x => !x.isSuspended);
        this.adoptedFroms = data.filter(x => !x.isSuspended);
      });
    },

    // save data to db
    save(type) {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      // validation before save
      this.validateDates();
      if (this.selectedItem.endDate < this.selectedItem.startDate) {
        this.doneAlert({ text: this.$t('startDateCantbeGreaterThanEndDate'), type: 'warning', timer: 10000 });
        return;
      }
      this.selectedItem.branchId = this.branchId;
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'RequestVacation',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          if (type === 'saveAndPrint') this.print(this.selectedItem.id);
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.$router.push({ name: 'vacationRequest' });
        });
      } else {
        this.create({
          url: 'RequestVacation',
          data: this.selectedItem,
        }).then((dataId) => {
          if (type === 'saveAndPrint') this.print(dataId);
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.$router.push({ name: 'vacationRequest' });
        });
      }
    },
    print(itemId) {
      const reportName = this.isRight ? 'VacationRequest-ar' : 'VacationRequest-en';
      const printedItem = {
        vacationRequestId: itemId,
      }
      this.printReport(reportName, printedItem);
    },
  },
};
</script>
<style>
.header {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #a074c7;
  border-radius: 5px;
}

.hide-code {
  display: none;
}

.header h4 {
  color: white;
}
</style>
